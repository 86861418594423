@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Headline'; /*a name to be used later*/
  src: url("./coolvetica rg.otf"); /*URL to font*/
}

.App {
  text-align: center;
  background:rgba(224, 222, 222, 0.19);
  min-height: 140vh;
}

.alert-light {
  background: #e8e8ef
}
.dropdown-toggle::after {
  display: none; 
}

.PopupWindow {
  padding: 1%;
  margin-bottom: 2%;
  margin-left: 4%;
  margin-right: 4%;
}

.btn-close {
  position: relative;
  top: 0px;
  right:0px;
  float: right;
 }

.MiniPill {
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 10px;
  border-radius: 15px;

}

.Removable::after {
  content: " \00d7";
  color: #FFF;
  text-align: center;
}

.StatsStudentListSettings {
  background-color: rgb(255, 250, 233);
  padding: 2%;
  border-radius: 10px;
  margin-bottom: 2%;
  outline-style: solid;
  outline-color: rgb(165, 165, 165);
  outline-width: 1px;
}
.LoadingScreen {
  background-color: rgb(220, 220, 220);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:100vh;
}

.InlineDiv {
  display: inline-block;
}

.App-header {
  background-image: url(TEMP_BACKGROUND.jpg);
  min-height: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 0;

  & button {
    appearance: none;
    /* safari */
    -webkit-appearance: none;
    /* other styles for aesthetics */
    padding: 0.9em 0.9em;
    background-color: rgba(250, 243, 222, 0.9);
    margin: 1em;
    border: 1px solid rgba(250, 243, 222, 0.9);
    font-family: 'Courier New', Courier, monospace;
    border-radius: 0.2em;
    color: #000;
    cursor: pointer;
  }
}

.SearchBox {
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
}

.HideOnMobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.WrapOnMobile {
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.ShowOnMobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.Centered {
  align-items: center;
  text-align: center;
}

.App-header h2 {
  margin-bottom:3em
}

.Grayed {
  color:#b1b5b8
}

.list-group-item {


  .active {
    background-color:rgb(199, 218, 255);
  }
}

.MobileResponsive {
  @media screen and (min-width: 768px) {
    position: absolute;
    right: 3em;
  }
  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 8px;
  }

}

.MobileFlexAlign {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  
}

.EqualWidthChild {

  @media screen and (max-width: 767px) {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
}

.FaqBox {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align:left;
  border: 3px solid #fff;
  border-radius: 1em;
  padding: 6vmin;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
}

.OutlineObject {
  position: absolute;
  right: 5px; color: black;
  background-color: rgb(255, 243, 208);
  outline-style: solid;
  outline-color: rgb(252, 236, 188);
  outline-width: 1px;
}

.Badge {
  background-color: rgb(129, 130, 129);
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;

  &.OutlineObject {
    position: absolute;
    right: 5px; color: black;
    background-color: rgb(255, 252, 243);
    outline-style: solid;
    outline-color: rgb(145, 109, 0);
    outline-width: 1px;
    border-radius: 3px;
    font-family: 'Courier New', Courier, monospace;
  }
}

.StatsSchoolInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    display: grid
  }
  
}

.VerticalDivider {
  margin-left: 1em;
  margin-right: 1em;
  border-left: 1px solid black;
}

.Navbutton {
  border-radius: 2px !important;
  
  &.active {
    background-color: rgba(153, 152, 152, 0.35) !important;
  }
}

.SessionContainer {
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-right: 3%;
  z-index: 99;
}

.SessionHyperlink {
  margin: 1%;
  padding: 1%;

  @media screen and (max-width: 768px) {
    font-size: 120%;
  }

}


.StatsSchoolInfoContainerItem {
  padding: 2%;
  margin: 8%;
  background-color: rgb(250, 243, 222);
  width: 180px;
  height: 150px;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    justify-self: center;
    height: 100px
  }

  & h4 {
    font-family: 'Courier New', Courier, monospace;
  }
}

.MonospaceFont {
  font-family: 'Courier New', Courier, monospace;
}
// TODO: fix this
.DarkenOnHover:hover {
  filter: brightness(50%);
}

.StatsStudentContainer {
  margin: 4%;
  padding: 1%;
  border-radius: 8px;
  display:grid;
  background-color: rgb(250, 243, 222);
  & .accordion-button {
    background-color: rgb(250, 243, 222);
  }
  & .accordion-body {
    background-color: rgb(250, 243, 222);
  }
}

.SearchFormBackground {
  align-items: center;
  justify-content: center;
  border-radius: 0.65em;
  padding:15px;
  background: rgba(250, 243, 222, 0.58);
  backdrop-filter: blur(8px);
  max-width: 90%;
}

.Questions {
  text-align: left;
  padding: 2vmin;
  min-height: 100vh;
}

.UploadBox {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align:left;
  border: 3px solid #fff;
  border-radius: 1em;
  padding: 6vmin;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
  
  & button {
    appearance: none;
    /* safari */
    -webkit-appearance: none;
    /* other styles for aesthetics */
    padding: 0.9em 0.9em;
    background-color: rgb(0, 98, 255);
    margin: 1em;
    border: 1px solid rgb(0, 98, 255);
    border-radius: 0.2em;
    color: #ffffff;
    cursor: pointer;
  }
}



.form-switch {
  align-self: center;
  margin-right: 1em
}
.form-control[type=file] {
  max-width: 50%;
}

.ExamUploadBox {
  display: flex;
  align-items: left;
}

.UploadPage {
  min-height: 100vh;
}

.Image {
  align-items: center;
  width: 70%;
}

.UploadPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.Question {
  border: 3px solid #fff;
  border-radius: 1em;
  padding: 2vmin;
  width: 90%;
  min-height: 80px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;

  & .text {
    margin-bottom:3px;
    font-family: Helvetica;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
    height: 40px;
  }

  &.accordion-item:first-of-type { 
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }
  &.accordion-item:last-of-type { 
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }

}



.SmallText {
  margin-top: 1%;
  font-size: calc(7px + 1vmin);
}

.faqItem {
  margin: 0;
  background-color:white;
  color:black;
  width: 80%
}

.form-select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  padding: 1em 2em;
  background-color: #fff;
  margin: 1em 1.5em;
  border: 1px solid #caced1;
  border-radius: 0.2em;
  color: #000;
  cursor: pointer;
  width: fit-content;
}